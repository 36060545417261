// 1-免费试用, 2-服务保障, 3-客户案例, 4-市场活动, 5-常见问题, 6-产业课堂, 7-产品定价, 8-客户反馈, 9-侧边栏, 10-更多产品
export default {
    "freeUse": {
        "moduleType": 1,
        "moduleName": "免费试用",
    },
    "service": {
        "moduleType": 2,
        "moduleName": "服务保障",
    },
    "example": {
        "moduleType": 3,
        "moduleName": "客户案例",
    },
    "marketActivity": {
        "moduleType": 4,
        "moduleName": "市场活动",
    },
    "question": {
        "moduleType": 5,
        "moduleName": "常见问题",
    },
    "industryClass": {
        "moduleType": 6,
        "moduleName": "产业课堂",
    },
    "productPrice": {
        "moduleType": 7,
        "moduleName": "产品定价",
    },
    "feedback": {
        "moduleType": 8,
        "moduleName": "客户反馈",
    },
    "sidebar": {
        "moduleType": 9,
        "moduleName": "侧边栏",
    },
    "more": {
        "moduleType": 10,
        "moduleName": "更多产品",
    },
    "footer": {
        "moduleType": 11,
        "moduleName": "底部栏",
    }
};
